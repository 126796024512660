body, html, .root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-card {
  box-shadow: 5px 8px 24px 5px rgba(158, 150, 150, 0.2) !important;
}
.ant-card-head {
  border-bottom: none !important;
  padding-top: 15px !important;
}

.ant-btn-primary {
  background-color: #08979C;
}
