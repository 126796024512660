.module-buttons {
    position: absolute;
    top: 13px;
    right: 15px;

}

.container{
    max-width: 1200px;
    margin: auto;
    padding-bottom: 30px;
}